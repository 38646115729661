<template>
	<v-layout>
		<Dialog :dialog="personDialog" :dialogWidth="1000">
			<template v-slot:title>
				<span v-if="doUpdate">Update</span>
				<span v-else>Add</span>
				<span v-if="isTenant">&nbsp;Tenant</span><span v-else>&nbsp;Contact Person</span>
			</template>
			<template v-slot:body>
				<v-form
					ref="personForm"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="appendContactPerson"
				>
					<v-row class="mx-3">
						<v-col md="4">
							<label class="font-size-14 font-weight-500 required" for="y-salutation">Salutation</label>
							<SelectInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								:items.sync="listTitle"
								id="salutation"
								placeholder="Title"
								v-model.trim="contactPerson.salutation"
							></SelectInput>
						</v-col>
						<v-col md="4">
							<label class="font-size-14 font-weight-500 required" for="y-first_name">First Name</label>
							<TextInput
								hide-details
								placeholder="First Name"
								v-model.trim="contactPerson.first_name"
								:rules="[
									vrules.required(contactPerson.first_name, 'first name'),
									vrules.minLength(contactPerson.first_name, 'first name', 2),
									vrules.maxLength(contactPerson.first_name, 'first name', 100),
								]"
								:class="{
									required: !contactPerson.first_name,
								}"
								v-on:keyup="appendDisplayName()"
								:disabled="pageLoading"
								:loading="pageLoading"
							></TextInput>
						</v-col>
						<v-col md="4">
							<label
								class="font-size-14 font-weight-500"
								:class="{ required: value && value.length < 1 }"
								for="y-last_name"
								>Last Name</label
							>
							<TextInput
								hide-details
								placeholder="Last Name"
								v-model.trim="contactPerson.last_name"
								:rules="[
									value && value.length < 1 ? vrules.required(contactPerson.last_name, 'Last name') : true,
									vrules.minLength(contactPerson.last_name, 'Last name', 2),
									vrules.maxLength(contactPerson.last_name, 'Last name', 100),
								]"
								:class="{
									required: value && value.length < 1 ? !contactPerson.last_name : false,
								}"
								v-on:keyup="appendDisplayName()"
								:disabled="pageLoading"
								:loading="pageLoading"
							></TextInput>
						</v-col>
						<v-col md="4">
							<label class="font-size-14 font-weight-500 required" for="y-display_name"
								>Display Name</label
							>
							<TextInput
								hide-details
								placeholder="Display Name"
								v-model.trim="contactPerson.display_name"
								:rules="[
									vrules.required(contactPerson.display_name, 'display name'),
									vrules.minLength(contactPerson.display_name, 'display name', 2),
									vrules.maxLength(contactPerson.display_name, 'display name', 100),
								]"
								:class="{
									required: !contactPerson.display_name,
								}"
								v-on:keyup="appendDisplayName()"
								:disabled="pageLoading"
								:loading="pageLoading"
							></TextInput>
						</v-col>
						<v-col md="4">
							<label class="font-size-14 font-weight-500 required" for="y-mobile">Phone Number</label>
							<PhoneTemplate
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								:rules="[vrules.required(contactPerson.mobile, 'Mobile')]"
								:class="{
									required: !contactPerson.mobile,
								}"
								id="contactperson-fax"
								placeholder="Phone Number"
								v-model="contactPerson.mobile"
							></PhoneTemplate>
						</v-col>
						<v-col md="4">
							<label class="font-size-14 font-weight-500 required" for="y-email">Email</label>
							<EmailInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								:id="`person-email`"
								placeholder="Email Id"
								v-model.trim="contactPerson.email"
								:class="{
									required: !contactPerson.email,
								}"
								:rules="[vrules.required(contactPerson.email, 'Email')]"
							></EmailInput>
						</v-col>
						<v-col md="4">
							<label class="font-size-14 font-weight-500 required" for="designation">Designation</label>
							<SelectInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								append-outer-icon="mdi-cog"
								:items.sync="designationLists"
								placeholder="Select Designation"
								item-text="value"
								item-value="value"
								:id="`designation`"
								v-on:click:append-outer="manageDesignationDialog = true"
								v-model="contactPerson.designation"
								:class="{
									required: !contactPerson.designation,
								}"
								:rules="[vrules.required(contactPerson.designation, 'Designation')]"
							></SelectInput>
						</v-col>
					</v-row>
				</v-form>
			</template>
			<template v-slot:action>
				<v-btn v-on:click="$emit('close', true)" depressed tile> Cancel </v-btn>
				<v-btn
					v-on:click="appendContactPerson"
					:disabled="!formValid"
					class="white--text mr-2"
					depressed
					color="blue darken-4"
					tile
				>
					<span v-if="doUpdate">Update</span>
					<span v-else>Add</span>
				</v-btn>
			</template>
		</Dialog>
		<template v-if="manageDesignationDialog">
			<ManageDesignation
				:dialog="manageDesignationDialog"
				:designation="designationLists"
				v-on:close-dialog="manageDesignationDialog = false"
				v-on:get-designation="getDesignations"
			></ManageDesignation>
		</template>
	</v-layout>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import EmailInput from "@/view/components/EmailInput";
import PhoneTemplate from "@/view/components/Phone";
import SelectInput from "@/view/components/SelectInput";
import ManageDesignation from "@/view/module/contacts/Manage-Designation.vue";
import ApiService from "@/core/services/api.service";
import { SET_ERROR } from "@/core/services/store/common.module";
import { cloneDeep, find, filter } from "lodash";

export default {
	model: {
		prop: "value",
		event: "input",
	},
	data() {
		return {
			formValid: true,
			contactPersons: [],
			designationLists: [],
			manageDesignationDialog: false,
			pageLoading: false,
			contactPerson: {
				id: null,
				salutation: "mr",
				first_name: null,
				last_name: null,
				display_name: null,
				email: null,
				mobile: null,
				designation: null,
			},
		};
	},
	components: {
		Dialog,
		TextInput,
		EmailInput,
		SelectInput,
		ManageDesignation,
		PhoneTemplate,
	},
	props: {
		value: {
			type: Array,
			default() {
				return [];
			},
		},
		personDialog: {
			type: Boolean,
			default: false,
		},
		isTenant: {
			type: Boolean,
			default: false,
		},
		doSave: {
			type: Boolean,
			default: false,
		},
		doUpdate: {
			type: Boolean,
			default: false,
		},
		customerId: {
			type: Number,
			default: 0,
		},
		personId: {
			type: Number,
			default: 0,
		},
	},
	watch: {
		personDialog() {
			this.initComponent();
			this.getDesignations();
		},
		detail: {
			deep: true,
			handler() {
				this.makeDefaultEntry();
			},
		},
	},
	methods: {
		initComponent() {
			const result = cloneDeep(this.value);
			this.contactPersons = result;
			if (this.doUpdate) {
				this.$nextTick(() => {
					const contact_person = find(this.contactPersons, {
						id: this.personId,
					});
					if (contact_person) {
						this.$nextTick(() => {
							this.contactPerson = {
								id: contact_person.id,
								salutation: contact_person.salutation ?? null,
								first_name: contact_person.first_name ?? null,
								last_name: contact_person.last_name ?? null,
								display_name: contact_person.display_name ?? null,
								email: contact_person.email ?? null,
								mobile: contact_person.mobile ?? null,
								designation: contact_person.designation ?? null,
							};
						});
					}
				});
			} else {
				this.$nextTick(() => {
					this.contactPerson = {
						id: null,
						salutation: "mr",
						first_name: null,
						last_name: null,
						display_name: null,
						email: null,
						mobile: null,
						designation: null,
					};
				});
			}
		},
		appendDisplayName() {
			if (!this.doUpdate) {
				const result = [];
				const { first_name, last_name } = this.contactPerson;
				if (first_name) {
					result.push(first_name);
				}
				if (last_name) {
					result.push(last_name);
				}
				this.contactPerson.display_name = result.join(" ");
			}
		},
		async appendContactPerson() {
			if (!this.$refs.personForm.validate()) {
				return false;
			}

			let email = this.contactPerson.email;
			var emailsObj = filter(this.contactPersons, function (contactpersn) {
				if (contactpersn.email == email) return contactpersn.email;
			});

			if (emailsObj.length > 0) {
				this.$store.commit(SET_ERROR, [{ model: true, message: `Email ${email} already is used` }]);
				return false;
			}

			this.contactPersons.push({
				id: this.contactPerson.id,
				salutation: this.contactPerson.salutation,
				first_name: this.contactPerson.first_name,
				last_name: this.contactPerson.last_name,
				display_name: this.contactPerson.display_name,
				email: this.contactPerson.email,
				mobile: this.contactPerson.mobile,
				designation: this.contactPerson.designation,
			});

			this.$emit("input", this.contactPersons);

			this.$emit("update:person", this.contactPerson);

			this.$nextTick(() => {
				this.contactPerson = {
					id: null,
					salutation: "mr",
					first_name: null,
					last_name: null,
					display_name: null,
					designation: null,
					email: null,
					mobile: null,
				};
			});

			this.$emit("close", true);
		},
		getDesignations() {
			this.pageLoading = true;
			ApiService.setHeader();
			ApiService.get(`contact-options`)
				.then(({ data }) => {
					this.designationLists = data.designation;
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	mounted() {
		this.initComponent();
		//this.getDesignations();
	},
};
</script>
